import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';

const Wrapper = styled.ol`
  display: flex;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
`;

const Step = styled.li<{ isPreviousPage: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 64px;
  font: ${theme.fonts.bold_16_24};
  color: #4c586f;
  text-align: center;

  /* 平行四辺形を作成 */
  &:before {
    transform: skewX(-25deg);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: #f7f7f7;
  }

  /* アクティブのステッパーを装飾 */
  &.active {
    color: #ffffff;
  }
  &.active:before {
    background-color: #a2aab0;
  }

  /* Negativeマージンで平行四辺形を調整  */
  &:first-child:before {
    margin-left: -20px;
  }
  &:last-child:before {
    margin-right: -20px;
  }

  &:not(:last-child) {
    margin-right: 3px;
  }

  br {
    display: none;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_14_21};
    height: 84px;

    /* スマホのときにステッパーの文字列を改行できるように対応  */
    br {
      display: inline-block;
    }
  }

  ${({ isPreviousPage }) =>
    isPreviousPage &&
    `
    &:before {
      background-color: #f1f1f1;
    }
    color: #cccccc;
    `}
`;

export type StepType = {
  step: number;
  text: JSX.Element;
};

type StepperProps = {
  current: number;
  steps: StepType[];
} & React.HTMLAttributes<HTMLOListElement>;

const Stepper = ({ current, steps, ...props }: StepperProps) => {
  return (
    <Wrapper {...props}>
      {steps.map((v, i) => (
        <Step
          isPreviousPage={current > v.step}
          className={classnames({ active: current === v.step, first: i === 0 })}
          key={i}
        >
          {v.text}
        </Step>
      ))}
    </Wrapper>
  );
};

export default Stepper;
