import React from 'react';
import { Control, Controller, useFormState } from 'react-hook-form';
import styled from 'styled-components';
import Button from '@/components/atoms/Button';
import { VerifyFormState } from '../state';
import { Breakpoints } from '@/constants/constants';
import TextField from '@/components/atoms/TextField';
import theme from '@/styles/theme';
import { useMediaQuery } from 'react-responsive';
import ErrorMessage from '@/components/atoms/ErrorMessage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 17px;
  }
`;

const Background = styled.div`
  width: 1062px;
  height: 350px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 60px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 100%;
    padding: 0px 15px;
  }
`;

const VerificationCodeTextField = styled(TextField)`
  width: 408px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  ::placeholder {
    text-align: left;
    font: ${theme.fonts.normal_16_32};
    letter-spacing: 0px;
    color: #cccccc;
    opacity: 1;
    padding-left: 15px;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin: 0px auto;
  }
`;

const VerificationCodeTextFieldWrapepr = styled.div`
  width: 408px;
  display: flex;
  margin: 0px auto;
  flex-direction: column;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column-reverse;
  }
`;

const DescriptionArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px auto 30px auto;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 305px;
  }
`;

const Description = styled.p`
  text-align: center;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
`;
const WarningDescription = styled.p`
  text-align: center;
  font: ${theme.fonts.bold_22_33};
  letter-spacing: 0px;
  color: #ff0000;
  opacity: 1;
`;
const RegistButton = styled(Button)`
  width: 299px;
  height: 64px;
  background: #4c586f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;

  padding: 0px;
  text-align: center;
  font: ${theme.fonts.bold_22_33};
  letter-spacing: 0px;
  color: #ffffff;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin: 0px auto 20px auto;
  }
`;
const DisabledButton = styled.button`
  width: 299px;
  height: 64px;
  background: ${theme.colors.buttonBorder};
  border-radius: 4px;
  opacity: 1;

  padding: 0px;
  text-align: center;
  font: ${theme.fonts.bold_22_33};
  color: ${theme.colors.buttonTextPrimary};
  letter-spacing: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin: 0px auto 20px auto;
  }
`;

type Props = {
  email: string;
  control: Control<VerifyFormState>;
  onClickNext: () => void;
};

const Verify = (props: Props) => {
  const { control, onClickNext } = props;
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });

  const { errors, isValid, isSubmitting } = useFormState({
    control
  });

  const handleOnClickSubmit = () => {
    onClickNext();
  };

  return (
    <Wrapper>
      <Background>
        <DescriptionArea>
          {!isSpMode ? (
            <>
                <WarningDescription>まだ登録は完了していません。画面を閉じないでください。</WarningDescription>
              <Description>ご登録いただいたメールアドレスに認証用メールを送信しました。</Description>
              <Description>認証用メールに記載された認証コードをご入力ください。</Description>
            </>
          ) : (
            <>
            <WarningDescription>まだ登録は完了していません。画面を閉じないでください。</WarningDescription>
              <Description>ご登録いただいたメールアドレスに</Description>
              <Description>認証用メールを送信しました。</Description>
              <Description>認証用メールに記載された認証コードを</Description>
              <Description>ご入力ください。</Description>
            </>
          )}
        </DescriptionArea>
        <VerificationCodeTextFieldWrapepr>
          <Controller
            name="verifyCode"
            control={control}
            defaultValue={""}
            render={({ field }) => <VerificationCodeTextField placeholder={'認証コード'} type="text" {...field}  />}
          />
          <ErrorMessage>{errors?.verifyCode && errors.verifyCode?.message}</ErrorMessage>
        </VerificationCodeTextFieldWrapepr>
        <ButtonArea>
          {isValid && !isSubmitting ?<RegistButton onClick={handleOnClickSubmit}>認証する</RegistButton>
          :<DisabledButton onClick={handleOnClickSubmit} disabled>認証する</DisabledButton>}
        </ButtonArea>
      </Background>
    </Wrapper>
  );
};

export default Verify;
