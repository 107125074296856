import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Auth from '@aws-amplify/auth';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import * as member from '@/state/modules/member';
import Stepper, { StepType } from '@/components/atoms/Stepper';
import { inputFormState, inputFormSchema, VerifyFormState, verifySchema } from './state';
import Input from './Input';
import Confirm from './Confirm';
import Verify from './Verify';
import Complete from './Complete';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import { Main } from '@/components/layout/common';
import PageTitle from '@/components/atoms/PageTitle';
import SlashIcon from '@/components/atoms/Icons/SlashIcon';
import { FetchStatus } from '@/models/enum/app';
import { ResponseRegisterMailAddress } from 'openapi/kusabi-frontgw-spec';
import { useFetchDoneEffect } from '@/common/hooks';
import { getPartneridForSessionStorage } from '@/common/utilities';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 61px 0 0 0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 71px 0 0 0;
  }
`;

const SubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 32px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 17px;
  }
`;

const SubTitle = styled.h2`
  width: 100%;
  font: ${theme.fonts.bold_22_33};
  color: #4c586f;
  padding-left: 20px;
`;

const FormArea = styled.div`
  width: 100%;
  max-width: 1062px;
`;

export const Pages = {
  /**
   * 会員情報入力ページ番号
   */
  Input: 1,
  /**
   * 会員情報入力確認ページ番号
   */
  Confirm: 2,
  /**
   * メールアドレス認証ページ番号
   */
  Verify: 3,
  /**
   * 会員登録完了ページ番号
   */
  Complete: 4,
} as const;
export type PageType = typeof Pages[keyof typeof Pages];

const steps: StepType[] = [
  {
    step: Pages.Input,
    text: (
      <span>
        会員情報
        <br />
        の入力
      </span>
    ),
  },
  {
    step: Pages.Confirm,
    text: (
      <span>
        入力内容
        <br />
        の確認
      </span>
    ),
  },
  {
    step: Pages.Verify,
    text: (
      <span>
        メール
        <br />
        アドレス
        <br />
        認証
      </span>
    ),
  },
  {
    step: Pages.Complete,
    text: (
      <span>
        会員
        <br />
        登録
        <br />
        完了
      </span>
    ),
  },
];

const SubTitleList: string[] = ['会員情報の入力', '入力内容の確認', 'メールアドレス認証', '会員登録完了'];

const AccountRegistration = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<PageType>(Pages.Input);
  const isRegisteredMailAddressStatus: FetchStatus = useSelector(member.selectors.fetchIsRegisteredMailAddressStatus);
  const isRegisteredMailAddress: { result: ResponseRegisterMailAddress } = useSelector(
    member.selectors.fetchIsRegisteredMailAddress
  );
  const [isAlreadyRegisteredMailAddress, setIsAlreadyRegisteredMailAddress] = useState<boolean>(false);
  const [userSub, setUserSub] = useState<string>('');

  // 会員情報入力ページのフォーム情報
  const {
    handleSubmit: handleSubmitInputForm,
    setValue,
    getValues: inputFormValues,
    control: inputFormControl,
  } = useForm<inputFormState>({
    mode: 'all',
    resolver: yupResolver(inputFormSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      email: '',
      password: '',
      phoneNumber: '',
      workPlace: '',
      mailDelivery: true,
      birthYear: 1990,
      birthMonth: 1,
      birthDay: 1
    },
  });

  // メールアドレス認証ページのフォーム情報
  const {
    handleSubmit: handleSubmitVerifyForm,
    getValues: verifyFormValues,
    control: verifyFormControl,
  } = useForm<VerifyFormState>({
    mode: 'all',
    resolver: yupResolver(verifySchema),
    defaultValues: {},
  });

  /**
   * 前画面に戻る
   */
  const handleOnClickPrev = (pageNumber: PageType) => {
    setPage(pageNumber);
  };

  /**
   * 確認画面に進む
   */
  const checkEmail: SubmitHandler<inputFormState> = (data) => {
    // 入力フィールドで「1.0」など入力した際に、「1」としてセット
    setValue('annualIncome', data.annualIncome);
    setValue('totalLoanAmount', data.totalLoanAmount);
    setValue('serviceYears', data.serviceYears);
    try {
      const { email } = inputFormValues();
      dispatch(member.operations.getIsRegisteredMailAddress({ mailAddress: email }));
    } catch (err) {
      console.warn(err);
    }
  };

  /**
   * cognitoに仮登録する
   */
  const signUp = async () => {
    try {
      const { email, password } = inputFormValues();

      // Cognitoに登録
      await Auth.signUp({
        username: email,
        password: password,
      }).then((data) => {
        setUserSub(data.userSub);
        setPage(Pages.Verify);
      });

      // DBに登録
    } catch (err) {
      console.warn(err);
    }
  };

  /**
   * メールアドレスを認証してDBに登録する
   */
  const verify: SubmitHandler<VerifyFormState> = async () => {
    try {
      console.log(userSub);
      if (userSub != '') {
        await Auth.confirmSignUp(inputFormValues('email'), verifyFormValues('verifyCode')).then((data) => {
          const {
            email,
            birthYear,
            birthMonth,
            birthDay,
            firstName,
            firstNameKana,
            lastName,
            lastNameKana,
            phoneNumber,
            annualIncome,
            totalLoanAmount,
            workPlace,
            serviceYears
          } = inputFormValues();
          const birthday = format(new Date(birthYear, birthMonth - 1, birthDay), 'yyyy-MM-dd');
          dispatch(
            member.operations.createMember({
              lastName: lastName,
              firstName: firstName,
              lastNameKana: lastNameKana,
              firstNameKana: firstNameKana,
              mailAddress: email,
              phoneNumber: phoneNumber,
              annualIncome: annualIncome,
              birthday: birthday,
              totalLoanAmount: totalLoanAmount,
              workPlace: workPlace,
              serviceYears: serviceYears,
              awsCognitoId: userSub,
              partnerId: getPartneridForSessionStorage(),
            })
          );
          console.log(data);
          setPage(Pages.Complete);
        });
      }
    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useFetchDoneEffect(isRegisteredMailAddressStatus, async () => {
    if (isRegisteredMailAddress.result.isRegistered) {
      setIsAlreadyRegisteredMailAddress(isRegisteredMailAddress.result.isRegistered);
      return;
    }
    if (!isRegisteredMailAddress.result.isRegistered) {
      setIsAlreadyRegisteredMailAddress(false);
      if (page === Pages.Input) {
        setPage(Pages.Confirm);
      } else if (page === Pages.Confirm) {
        await signUp();
      }
    }
  });

  return (
    <Main>
      <PageTitle>新規会員登録</PageTitle>

      <Wrapper>
        <FormArea>
          <Stepper current={page} steps={steps} />

          <SubTitleWrapper>
            <SlashIcon />
            <SubTitle>{SubTitleList[page - 1]}</SubTitle>
          </SubTitleWrapper>

          {/* 会員情報入力ページ */}
          {page === Pages.Input && (
            <Input
              control={inputFormControl}
              onClickNext={handleSubmitInputForm(checkEmail)}
              isRegisteredMailAddress={isAlreadyRegisteredMailAddress}
            />
          )}

          {/* 会員情報入力確認ページ */}
          {page === Pages.Confirm && (
            <Confirm
              data={inputFormValues()}
              onClickPrev={handleOnClickPrev}
              onClickNext={handleSubmitInputForm(checkEmail)}
              isRegisteredMailAddress={isAlreadyRegisteredMailAddress}
            />
          )}

          {/* メールアドレス認証ページ */}
          {page === Pages.Verify && (
            <Verify
              email={inputFormValues('email')}
              control={verifyFormControl}
              onClickNext={handleSubmitVerifyForm(verify)}
            />
          )}

          {/* 会員登録完了ページ */}
          {page === Pages.Complete && <Complete />}
        </FormArea>
      </Wrapper>
    </Main>
  );
};

export default AccountRegistration;
