import React from 'react';
import AccountRegistration from '@/components/organisms/Buyer/Account/Registration';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import BuyerHeader from '@/components/atoms/Header/BuyerHeader';
import BuyerFooter from '@/components/atoms/Footer/BuyerFooter';
import { Wrapper } from '@/components/layout/common';

const RegistrationPage = () => {
  return (
    <Wrapper>
      <BuyerHeader displayPattern="logo" />
      <AccountRegistration />
      <BuyerFooter />
    </Wrapper>
  );
};

export default withInitializeApp(RegistrationPage);
