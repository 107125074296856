import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';

const CheckboxWrapper = styled.div<{ isNoBorder: boolean }>`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: 64px;
    padding-left: 20px;
    ${({ isNoBorder }) => (isNoBorder ? ` border: none; ` : `border: 1px solid ${theme.colors.inputBorder};`)}
    border-radius: 4px;
  }
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })<{ isDisable: boolean }>`
  position: absolute;
  opacity: 0;

  /* チェック時、レ点を表示 */
  &:checked + label::after {
    opacity: 1;
  }

  /* チェック時、背景色を変更 */
  &:checked + label::before {
    ${({ isDisable }) =>
      isDisable
        ? ` background-color: ${theme.colors.cardHeadingBackground}; `
        : ` background-color: ${theme.colors.gold}; `}
  }
`;

const CheckboxLabel = styled.label<{ isDisable: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0 0 30px;
  cursor: pointer;
  white-space: nowrap;

  &::before,
  &::after {
    position: absolute;
    content: '';
    left: 0;
  }

  /* チェックボックスの枠を作成 */
  &::before {
    background: #d1a827;
    width: 24px;
    height: 24px;
    ${({ isDisable }) =>
      isDisable ? ` border: 2px solid ${theme.colors.sea}; ` : ` border: 3px solid ${theme.colors.gold}; `}
    border-radius: 4px;
    z-index: 1;
    background-color: ${theme.colors.white};
  }

  /* チェックボックスのレ点を作成 */
  &::after {
    margin: auto;
    width: 14px;
    height: 7px;
    ${({ isDisable }) =>
      isDisable
        ? ` border-bottom: 3px solid ${theme.colors.sea};
    border-left: 3px solid ${theme.colors.sea};`
        : ` border-bottom: 3px solid ${theme.colors.cardHeadingBackground};
    border-left: 3px solid ${theme.colors.cardHeadingBackground}; `}
    transform-origin: center;
    transform: translate(5px, -2px) rotate(-45deg);
    z-index: 1;
    opacity: 0;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 100%;
  }
`;

type Props = InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  label?: string;
  isNoBorder?: boolean;
  isDisable?: boolean;
};

const Checkbox = (props: Props) => {
  // "type" プロパティ以外のすべてのプロパティを StyledCheckbox に渡したいので type だけ明示的に受けてそれ以外を restProps で受ける
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, label, isNoBorder, isDisable, type, ...restProps } = props;
  return (
    <CheckboxWrapper isNoBorder={isNoBorder ? isNoBorder : false}>
      <StyledCheckbox isDisable={isDisable ? isDisable : false} id={id} {...restProps} />
      <CheckboxLabel isDisable={isDisable ? isDisable : false} htmlFor={id}>
        {label}
      </CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default Checkbox;
