import * as yup from 'yup';
import { YupErrorMessage } from '@/constants/messages';
import {
  PASSWORD_VALIDATOR,
  REGEX_HIRAGANA_ZENKAKU,
  REGEXP_PHONE,
  REGEX_VERIFY_CODE,
  CAREER_MAILS,
} from '@/constants/constants';
import { multiByteCharacterValidator } from '@/utils/multiByteValidator';

/**
 *  会員登録情報
 */
export const inputFormSchema = yup.object({
  firstName: yup.string().trim().required(YupErrorMessage.required).max(100, YupErrorMessage.string.max).label('名'),
  lastName: yup.string().trim().required(YupErrorMessage.required).max(100, YupErrorMessage.string.max).label('姓'),
  firstNameKana: yup
    .string()
    .trim()
    .required(YupErrorMessage.required)
    .max(100, YupErrorMessage.string.max)
    .matches(REGEX_HIRAGANA_ZENKAKU, YupErrorMessage.string.hiragana_zen)
    .label('めい'),
  lastNameKana: yup
    .string()
    .trim()
    .required(YupErrorMessage.required)
    .max(100, YupErrorMessage.string.max)
    .matches(REGEX_HIRAGANA_ZENKAKU, YupErrorMessage.string.hiragana_zen)
    .label('せい'),
  email: yup
    .string()
    .trim()
    .test('should_not_career_mail', YupErrorMessage.string.should_not_career_mail, (value): boolean => {
      if (value && typeof value == 'string' && value.includes('@')) {
        const separated: string | undefined = value.split('@').pop();
        if (separated) {
          return !CAREER_MAILS.includes(separated);
        }
      }
      return true;
    })
    .test(
      'multi_byte_character_not_accepted',
      YupErrorMessage.string.multi_byte_character_not_accepted,
      multiByteCharacterValidator
    )
    .required(YupErrorMessage.required)
    .max(128, YupErrorMessage.string.max)
    .email(YupErrorMessage.string.email)
    .label('メールアドレス'),
  phoneNumber: yup
    .string()
    .trim()
    .test('phoneNumber_matches', YupErrorMessage.string.phoneNumber, (value) => {
      if (value) {
        return REGEXP_PHONE.test(value);
      }
      return true;
    })
    .required(YupErrorMessage.required)
    .label('電話番号'),
  password: PASSWORD_VALIDATOR.label('パスワード'),
  annualIncome: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .required(YupErrorMessage.required)
    .min(1, YupErrorMessage.number.min)
    .max(999999, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('年収'),
  birthYear: yup.number().required(YupErrorMessage.required).label('生年月日　年'),
  birthMonth: yup.number().required(YupErrorMessage.required).label('生年月日　月'),
  birthDay: yup.number().required(YupErrorMessage.required).label('生年月日　日'),
  totalLoanAmount: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .required(YupErrorMessage.required)
    .min(0, YupErrorMessage.number.min)
    .max(999999, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('総借入金額'),
  workPlace: yup.string().trim().max(255, YupErrorMessage.string.max).label('勤務先'),
  serviceYears: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value))
    .min(0, YupErrorMessage.number.min)
    .max(100, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('勤続年数'),
  mailDelivery: yup.boolean().label('配信メール'),
});
export type inputFormState = yup.InferType<typeof inputFormSchema>;

/**
 *  メールアドレス認証情報
 */
export const verifySchema = yup.object({
  verifyCode: yup
    .string()
    .trim()
    .required(YupErrorMessage.required)
    .matches(REGEX_VERIFY_CODE, YupErrorMessage.string.verifyCode)
    .label('認証コード'),
});
export type VerifyFormState = yup.InferType<typeof verifySchema>;
