import { Routings } from '@/common/routings';
import InfoIcon from '@/components/atoms/Icons/InfoIcon';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 30px 110px;
  background: ${theme.colors.backgroundLight};
  border-radius: 10px;
  font: ${theme.fonts.normal_12_22};
  color: ${theme.colors.text};
  align-items: flex-start;
  justify-content: start;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 20px 0px 20px 17px;
  }
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    align-items: flex-start;
  }
`;

const Content = styled.p`
  margin-left: 12px;
`;

const Link = styled.a`
  color: ${theme.colors.gold};
  text-decoration: none;
`;

const DummyElement = styled.div`
  width: 18px;
  visibility: hidden;
`;

const TermArea = styled.div`
  width: 626px;
  height: 121px;
  border-radius: 10px;
  opacity: 1;
  margin: 60px auto;
  display: flex;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 145px;
    margin-bottom: 40px;
  }
`;

const Terms = () => (
  <Wrapper>
    <Container>
      <ContentRow>
        <InfoIcon color="backgroundLight" />
        <Content>
          ※ 利用規約については、
          <Link href={Routings.terms.location} target="_blank" rel="noreferrer">
            こちら
          </Link>
          をご確認ください。
        </Content>
      </ContentRow>
      <ContentRow>
        <DummyElement />
        <Content>
          ※ 個人情報の取扱いについては、
          <Link href={Routings.privacy.location} target="_blank">
            こちら
          </Link>
          をご確認ください。
        </Content>
      </ContentRow>
      <ContentRow>
        <DummyElement />
        <Content>※ お客さまの入力情報は、暗号化され守られております。</Content>
      </ContentRow>
    </Container>
  </Wrapper>
);

export const TermsInArea = () => (
    <TermArea><Terms /></TermArea>
);

export default Terms;
