import React, { memo } from 'react';

type Props = {
  className?: string;
};

const ExpandMoreIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="#000000"
      style={{ transform: 'scale(1.3)' }}
      className={className}
    >
      <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
    </svg>
  );
};

export default memo(ExpandMoreIcon);
