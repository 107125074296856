import React from 'react';
import { Control, Controller, useFormState, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { range } from '@/common/utilities';
import TextField from '@/components/atoms/TextField';
import PasswordTextField from '@/components/atoms/PasswordTextField';
import Checkbox from '@/components/atoms/Checkbox';
import Select from '@/components/atoms/Select';
import { Breakpoints } from '@/constants/constants';
import { inputFormState } from '../state';
import theme from '@/styles/theme';
import { YupErrorMessage } from '@/constants/messages';
import ErrorMessage from '@/components/atoms/ErrorMessage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 17px;
  }
`;

const Description = styled.h3`
  margin-top: 60px;
  margin-bottom: 31px;
  text-align: left;
  color: #333333;
  font: ${theme.fonts.normal_16_32};

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.normal_14_28};
  }
`;

const FormContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  font: ${theme.fonts.normal_14_28};
  color: #333333;
`;

const Row = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 1px solid #a2aab0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: flex;
    flex-direction: column;

    border: none;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }
`;

const Th = styled.th`
  width: 299px;
  max-width: 299px;
  vertical-align: top;
  padding: 30px 20px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_16_32};
  color: #333333;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    max-width: 100%;
    vertical-align: baseline;
    padding: 0 0 20px 0;
    background: #fff;
    font: ${theme.fonts.bold_20_32};
    border-bottom: 1px solid #a2aab0;
  }
`;

const Td = styled.td`
  width: 763px;
  padding: 30px 0 30px 28px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 20px 0 0 0;
  }
`;

const InnerTh = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  label {
    text-align: left;
  }
`;

const InnerTd = styled.div`
  width: 436px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const NameAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    row-gap: 0;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const NameArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    justify-content: space-between;
  }
`;

const NameTextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 47%;
    margin-right: 0;
  }
`;

const NameTextFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 190px;

  label {
    font: ${theme.fonts.bold_16_32};
    color: #333333;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 100%;
  }
`;

const NameTextField = styled(TextField)`
  width: 143px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const AmountTextFieldWrapper = styled.div`
  width: 232px;
  display: flex;
  flex-direction: row;

  label {
    white-space: nowrap;
    font: ${theme.fonts.normal_16_32};
    color: #333333;
    padding-left: 10px;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const EmailCaution = styled.p`
  width: 100%;
  margin-top: 8px;
  font: ${theme.fonts.normal_12_20};
  color: #333333;
`;

const PasswordCaution = styled.div`
  width: 100%;
  color: #333333;

  p {
    font: ${theme.fonts.normal_16_32};
  }

  ul li {
    list-style: inside disc;
    font: ${theme.fonts.normal_12_24};
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    p {
      font: ${theme.fonts.normal_14_32};
    }
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SelectWrapper = styled.div`
  width: 116px;
  display: flex;
  direction: row;
  align-items: center;

  label {
    padding-left: 10px;
    font: ${theme.fonts.normal_16_32};
    color: #333333;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 38%;

    &:not(:first-child) {
      width: 32%;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const ServiceYearsTextFieldWrapper = styled.div`
  width: 116px;
  display: flex;

  label {
    padding-left: 10px;
    font: ${theme.fonts.normal_16_32};
    color: #333333;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const Required = styled.span`
  display: inline-block;
  height: 20px;
  background-color: #fff;
  border: 1px solid #d1a827;
  border-radius: 4px;
  font: ${theme.fonts.bold_10_20};

  color: #d1a827;
  min-width: 10px;
  padding: 3px 7px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
`;

const ButtonArea = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 61px;
  margin-bottom: 120px;
`;

const StylecButton = styled.button`
  width: 408px;
  height: 73px;
  background: #4c586f;
  border-radius: 4px;

  font: ${theme.fonts.bold_22_33};
  color: #ffffff;

  opacity: 1;
  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const YEARS = range(1900, new Date().getFullYear());
const MONTHS = range(1, 12);
const getDays = (year: number, month: number) => {
  const lastDay = new Date(year, month, 0).getDate();
  return lastDay ? range(1, lastDay) : [];
};

type Props = {
  control: Control<inputFormState>;
  onClickNext: () => void;
  isRegisteredMailAddress: boolean;
};

const Input = (props: Props) => {
  const { control, onClickNext, isRegisteredMailAddress } = props;
  const { errors } = useFormState({
    control,
  });

  const watchBirthYear = useWatch({
    control,
    name: 'birthYear',
  });

  const watchBirthMonth = useWatch({
    control,
    name: 'birthMonth',
  });

  const handleOnSubmit = () => {
    onClickNext();
  };

  return (
    <Wrapper>
      <FormContainer>
        <tbody>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="lastName">お名前</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <NameAreaContainer>
                  <NameArea>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="lastName">姓</label>
                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field }) => <NameTextField id="lastName" type="text" {...field} />}
                        />
                      </NameTextFieldWrapper>
                      {errors?.lastName && <ErrorMessage>{errors.lastName?.message}</ErrorMessage>}
                    </NameTextFieldContainer>

                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="firstName">名</label>
                        <Controller
                          name="firstName"
                          control={control}
                          render={({ field }) => <NameTextField id="firstName" type="text" {...field} />}
                        />
                      </NameTextFieldWrapper>
                      {errors?.firstName && <ErrorMessage>{errors.firstName?.message}</ErrorMessage>}
                    </NameTextFieldContainer>
                  </NameArea>

                  <NameArea>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="lastNameKana">せい</label>
                        <Controller
                          name="lastNameKana"
                          control={control}
                          render={({ field }) => <NameTextField id="lastNameKana" type="text" {...field} />}
                        />
                      </NameTextFieldWrapper>
                      {errors?.lastNameKana && <ErrorMessage>{errors.lastNameKana?.message}</ErrorMessage>}
                    </NameTextFieldContainer>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="firstNameKana">めい</label>
                        <Controller
                          name="firstNameKana"
                          control={control}
                          render={({ field }) => <NameTextField id="firstNameKana" type="text" {...field} />}
                        />
                      </NameTextFieldWrapper>
                      {errors?.firstNameKana && <ErrorMessage>{errors.firstNameKana?.message}</ErrorMessage>}
                    </NameTextFieldContainer>
                  </NameArea>
                </NameAreaContainer>
              </InnerTd>
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="email">メールアドレス</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => <TextField id="email" type="email" {...field} />}
                />
                <EmailCaution>※キャリアメールはご登録いただけません。</EmailCaution>
                {errors?.email && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
                {isRegisteredMailAddress && <ErrorMessage>{YupErrorMessage.string.registered_email}</ErrorMessage>}
              </InnerTd>
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="password">パスワード</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <PasswordCaution>
                  <p>以下の条件を満たすパスワードを設定してださい</p>
                  <ul>
                    <li>10文字以上</li>
                    <li>メールアドレスと異なる</li>
                  </ul>
                </PasswordCaution>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => <PasswordTextField id="password" {...field} />}
                />
              </InnerTd>
              {errors?.password && <ErrorMessage>{errors.password?.message}</ErrorMessage>}
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="phoneNumber">電話番号</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => <TextField id="phoneNumber" type="tel" {...field} />}
                />
              </InnerTd>
              {errors?.phoneNumber && <ErrorMessage>{errors.phoneNumber?.message}</ErrorMessage>}
            </Td>
          </Row>

          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="birthYear">生年月日</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <SelectContainer>
                  <SelectWrapper>
                    <Controller
                      name="birthYear"
                      control={control}
                      render={({ field }) => (
                        <Select id="birthYear" aria-label="年" {...field}>
                          {YEARS.map((value) => (
                            <option key={value} value={value}>{`${value}`}</option>
                          ))}
                        </Select>
                      )}
                    />
                    <label>年</label>
                  </SelectWrapper>
                  <SelectWrapper>
                    <Controller
                      name="birthMonth"
                      control={control}
                      render={({ field }) => (
                        <Select id="birthMonth" aria-label="月" {...field}>
                          {MONTHS.map((value) => (
                            <option key={value} value={value}>{`${value}`}</option>
                          ))}
                        </Select>
                      )}
                    />
                    <label>月</label>
                  </SelectWrapper>
                  <SelectWrapper>
                    <Controller
                      name="birthDay"
                      control={control}
                      render={({ field }) => (
                        <Select id="birthDay" aria-label="日" {...field}>
                          {getDays(watchBirthYear, watchBirthMonth).map((value) => (
                            <option key={value} value={value}>{`${value}`}</option>
                          ))}
                        </Select>
                      )}
                    />
                    <label>日</label>
                  </SelectWrapper>
                </SelectContainer>
              </InnerTd>
            </Td>
          </Row>
        </tbody>
      </FormContainer>

      <Description>以下の情報は、借入可能金額および購入可能物件の算定に利用いたします。</Description>

      <FormContainer>
        <tbody>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="annualIncome">年収</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <AmountTextFieldWrapper>
                  <Controller
                    name="annualIncome"
                    control={control}
                    render={({ field }) => (
                      <TextField id="annualIncome" type="text" {...field} value={field.value ?? ''} />
                    )}
                  />
                  <label>万円</label>
                </AmountTextFieldWrapper>
              </InnerTd>
              {errors?.annualIncome && <ErrorMessage>{errors.annualIncome?.message}</ErrorMessage>}
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="totalLoanAmount">総借入金額</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <AmountTextFieldWrapper>
                  <Controller
                    name="totalLoanAmount"
                    control={control}
                    render={({ field }) => (
                      <TextField id="totalLoanAmount" type="text" {...field} value={field.value ?? ''} />
                    )}
                  />
                  <label>万円</label>
                </AmountTextFieldWrapper>
              </InnerTd>
              {errors?.totalLoanAmount && <ErrorMessage>{errors.totalLoanAmount?.message}</ErrorMessage>}
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="workPlace">勤務先</label>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="workPlace"
                  control={control}
                  render={({ field }) => <TextField id="workPlace" type="text" {...field} />}
                />
              </InnerTd>
              {errors?.workPlace && <ErrorMessage>{errors.workPlace?.message}</ErrorMessage>}
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="serviceYears">勤続年数</label>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <ServiceYearsTextFieldWrapper>
                  <Controller
                    name="serviceYears"
                    control={control}
                    render={({ field }) => (
                      <TextField id="serviceYears" type="number" {...field} value={field.value ?? ''} />
                    )}
                  />

                  <label>年</label>
                </ServiceYearsTextFieldWrapper>
              </InnerTd>
              {errors?.serviceYears && <ErrorMessage>{errors.serviceYears?.message}</ErrorMessage>}
            </Td>
          </Row>
        </tbody>
      </FormContainer>

      <Description>KUSABIより、新着物件情報やお知らせメールをお届けします。</Description>

      <FormContainer>
        <tbody>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="annualIncome">配信メール</label>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <Controller
                  name="mailDelivery"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      id="mailDelivery"
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                      label="受け取る"
                    />
                  )}
                />
              </InnerTd>
              {errors?.mailDelivery && <ErrorMessage>{errors.mailDelivery?.message}</ErrorMessage>}
            </Td>
          </Row>
        </tbody>
      </FormContainer>

      <ButtonArea>
        <StylecButton onClick={handleOnSubmit}>入力内容の確認</StylecButton>
      </ButtonArea>
    </Wrapper>
  );
};

export default Input;
