import Button from '@/components/atoms/Button';
import ErrorMessage from '@/components/atoms/ErrorMessage';
import { TermsInArea } from '@/components/atoms/Terms';
import { Breakpoints } from '@/constants/constants';
import { YupErrorMessage } from '@/constants/messages';
import theme from '@/styles/theme';
import { numberToJpYen } from '@/utils/numberFormatter';
import * as _ from 'lodash';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { Pages, PageType } from '..';
import { inputFormState } from '../state';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 15px;
  }
`;

const FormContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  font: ${theme.fonts.bold_16_32};
  color: #333333;
`;

const Row = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 1px solid #a2aab0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: flex;
    flex-direction: column;

    border: none;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }
`;

const Th = styled.th`
  width: 299px;
  max-width: 299px;
  vertical-align: top;
  padding: 30px 20px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_16_32};
  color: #333333;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    max-width: 100%;
    vertical-align: baseline;
    padding: 0 0 20px 0;
    background: #fff;
    font: ${theme.fonts.bold_20_32};
    border-bottom: 1px solid #a2aab0;
  }
`;

const InnerTh = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  label {
    text-align: left;
  }
`;

const Required = styled.span`
  display: inline-block;
  height: 20px;
  background-color: #fff;
  border: 1px solid #d1a827;
  border-radius: 4px;
  font: ${theme.fonts.bold_10_20};

  color: #d1a827;
  min-width: 10px;
  padding: 3px 7px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
`;

const Td = styled.td`
  width: 763px;
  padding: 30px 0 30px 28px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 20px 0 0 0;
  }
`;

const InnerTd = styled.div`
  width: 436px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const InnerTdValue = styled.p`
  display: inline-block;
  width: 150px;
  height: max-content;
  margin: 0px;
  font: ${theme.fonts.normal_16_32};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const NameAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    row-gap: 0;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const NameArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NameTextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 47%;
  }
`;

const NameTextFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 190px;

  label {
    display: inline-block;
    width: 32px;
    margin-right: 20px;
    white-space: nowrap;
    font: ${theme.fonts.bold_16_32};
    color: #333333;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    width: 100%;
  }
`;

const DiscriptionWrapper = styled.div`
  width: 1062px;
  display: flex;
  margin-top: 40px;
  margin-bottom: 30px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    overflow-wrap: break-word;
  }
`;

const Discription = styled.label`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.normal_14_28};
  }
`;

const ButtonArea = styled.div`
  width: 100%;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 54px;
  display: flex;
  justify-content: space-around;
  overflow-wrap: break-word;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column-reverse;
    margin-top: 0px;
  }
`;

const BackButton = styled(Button)`
  width: 190px;
  height: 64px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const BackButtonLabel = styled.label`
  width: 112px;
  height: 24px;
  opacity: 1;
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
  font: ${theme.fonts.bold_16_24};
  white-space: nowrap;
  text-align: center;
`;

const RegistButton = styled(Button)`
  width: 407px;
  height: 76px;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const RegistButtonSmallLabel = styled.label`
  display: inline-block;
  width: 216px;
  height: 17px;
  text-align: center;
  font: ${theme.fonts.bold_12_22};
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: -6px;
  margin-bottom: 6px;
`;

const RegistButtonLargeLabel = styled.label`
  display: inline-block;
  width: 198px;
  height: 32px;
  text-align: center;
  font: ${theme.fonts.bold_22_33};
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

const PseudoButton = styled.div`
  width: 190px;
  height: 64px;
`;

type Props = {
  data: inputFormState;
  onClickPrev: (pageNumber: PageType) => void;
  onClickNext: () => void;
  isRegisteredMailAddress: boolean;
};

const Confirm = (props: Props) => {
  const { data, onClickPrev, onClickNext, isRegisteredMailAddress } = props;
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });
  const {
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    email,
    phoneNumber,
    annualIncome,
    birthYear,
    birthMonth,
    birthDay,
    totalLoanAmount,
    workPlace,
    serviceYears,
    mailDelivery,
  } = data;

  const handleOnClickPrev = () => {
    onClickPrev(Pages.Input);
  };

  const handleOnClickSignUp = () => {
    onClickNext();
  };

  return (
    <Wrapper>
      <FormContainer>
        <tbody>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="lastName">お名前</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTd>
                <NameAreaContainer>
                  <NameArea>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="lastName">姓</label>
                        <InnerTdValue>{`${lastName}`}</InnerTdValue>
                      </NameTextFieldWrapper>
                    </NameTextFieldContainer>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="firstName">名</label>
                        <InnerTdValue>{`${firstName}`}</InnerTdValue>
                      </NameTextFieldWrapper>
                    </NameTextFieldContainer>
                  </NameArea>
                  <NameArea>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="lastNameKana">せい</label>
                        <InnerTdValue>{`${lastNameKana}`}</InnerTdValue>
                      </NameTextFieldWrapper>
                    </NameTextFieldContainer>
                    <NameTextFieldContainer>
                      <NameTextFieldWrapper>
                        <label htmlFor="firstNameKana">めい</label>
                        <InnerTdValue>{`${firstNameKana}`}</InnerTdValue>
                      </NameTextFieldWrapper>
                    </NameTextFieldContainer>
                  </NameArea>
                </NameAreaContainer>
              </InnerTd>
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="email">メールアドレス</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTdValue style={{ width: isSpMode ? '305px' : '600px', overflowWrap: 'break-word' }}>
                {email}
              </InnerTdValue>
              {isRegisteredMailAddress && <ErrorMessage>{YupErrorMessage.string.registered_email}</ErrorMessage>}
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="tel">電話番号</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTdValue>{phoneNumber}</InnerTdValue>
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="birth">生年月日</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTdValue>{`${birthYear}年${birthMonth}月${birthDay}日`}</InnerTdValue>
            </Td>
          </Row>
        </tbody>
      </FormContainer>
      <DiscriptionWrapper>
        <Discription>以下の情報は、借入可能金額および購入可能物件の算定に利用いたします。</Discription>
      </DiscriptionWrapper>
      <FormContainer>
        <tbody>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="annualIncome">年収</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTdValue>{numberToJpYen(annualIncome)}</InnerTdValue>
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="totalLoanAamount">総借入金額</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTdValue>{numberToJpYen(totalLoanAmount)}</InnerTdValue>
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="workPlace">勤務先</label>
              </InnerTh>
            </Th>
            <Td>
              <InnerTdValue>{workPlace}</InnerTdValue>
            </Td>
          </Row>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="serviceYears">勤続年数</label>
              </InnerTh>
            </Th>
            <Td>
              <InnerTdValue>{_.isNumber(serviceYears) && `${serviceYears}年`}</InnerTdValue>
            </Td>
          </Row>
        </tbody>
      </FormContainer>
      <DiscriptionWrapper>
        <Discription>KUSABIより、新着物件情報やお知らせメールをお届けします。</Discription>
      </DiscriptionWrapper>
      <FormContainer>
        <tbody>
          <Row>
            <Th>
              <InnerTh>
                <label htmlFor="mailDelivery">配信メール</label>
                <Required>必須</Required>
              </InnerTh>
            </Th>
            <Td>
              <InnerTdValue>{mailDelivery ? '受け取る' : '受け取らない'}</InnerTdValue>
            </Td>
          </Row>
        </tbody>
      </FormContainer>
      <TermsInArea />
      <ButtonArea>
        <BackButton onClick={handleOnClickPrev}>
          <BackButtonLabel>入力画面に戻る</BackButtonLabel>
        </BackButton>
        <RegistButton onClick={handleOnClickSignUp}>
          <RegistButtonSmallLabel>利用規約・個人情報の取扱いに同意して</RegistButtonSmallLabel>
          <RegistButtonLargeLabel>この内容で登録する</RegistButtonLargeLabel>
        </RegistButton>
        {!isSpMode && <PseudoButton />}
      </ButtonArea>
    </Wrapper>
  );
};

export default Confirm;
