import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import Button from '@/components/atoms/Button';
import { Routings } from '@/common/routings';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0 17px;
  }
`;

const Background = styled.div`
  width: 1062px;
  height: 280px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 60px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 100%;
  }
`;

const DescriptionArea = styled.div`
  width: 562px;
  height: 56px;
  display: flex;
  flex-direction: column;
  margin: 40px auto 0px auto;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: max-content;
    overflow-wrap: break-word;
    align-items: baseline;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const Description = styled.p`
  display: flex;
  flex-direction: column;
  font: ${theme.fonts.normal_16_32};
  white-space: nowrap;
  text-align: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    overflow-wrap: break-word;
    white-space: break-spaces;
  }
`;

const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  margin: 60px auto 60px auto;
  justify-content: center;
`;

const LoginButton = styled(Button)`
  width: 299px;
  height: 64px;
  background: #4c586f 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 305px;
    margin: 0px auto;
  }
`;

const Complete = () => {
  return (
    <Wrapper>
      <Background>
        <DescriptionArea>
          <Description>KUSABIにご登録いただき、ありがとうございます。</Description>
          <Description>ログインしていただくことで、KUSABIのすべての機能がご利用いただけます。</Description>
        </DescriptionArea>
        <ButtonArea>
          <LoginButton onClick={() => navigate(Routings.login.location)}>ログイン画面へ</LoginButton>
        </ButtonArea>
      </Background>
    </Wrapper>
  );
};

export default Complete;
