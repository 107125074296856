import React, { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import theme from '@/styles/theme';
import ExpandMoreIcon from '../Icons/ExpandMoreIcon';
import { Breakpoints } from '@/constants/constants';

const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: 50px;
  }
`;

const StyledSelect = styled.select`
  appearance: none;
  width: inherit;
  height: inherit;
  padding-left: 10px;
  font: ${theme.fonts.normal_16_32};
  color: ${theme.colors.text};
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  width: 34px;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

type Props = InputHTMLAttributes<HTMLSelectElement> & {
  className?: string;
};

const Select = forwardRef<HTMLSelectElement, InputHTMLAttributes<HTMLSelectElement>>((props: Props, ref) => {
  const { className, ...restProps } = props;

  return (
    <SelectWrapper className={className}>
      <StyledSelect {...restProps} ref={ref} />
      <IconWrapper>
        <ExpandMoreIcon />
      </IconWrapper>
    </SelectWrapper>
  );
});
Select.displayName = 'Select';

export default Select;
